import { LoadingActionTypes } from './types';

export const setLoading = (key: string) => ({
  type: LoadingActionTypes.SET_LOADING,
  payload: { key },
});

export const removeLoading = (key: string) => ({
  type: LoadingActionTypes.REMOVE_LOADING,
  payload: { key },
});
