import React from 'react';
import { Provider } from 'react-redux';
import { ConfigProvider } from 'antd';
import ruRU from 'antd/es/locale/ru_RU';
import { GlobalStyle } from 'config/globalStyles';
import theme from 'config/theme';
import { ConnectedRouter } from 'connected-react-router';
import moment from 'moment';
import { ThemeProvider } from 'styled-components';

import { storageTokenSyncStart } from 'modules/auth/utils';
import { RouterRoutes } from 'modules/router';
import store, { history } from 'modules/store';

import { Layout } from 'atomic/templates/Layout';
import ErrorBoundary from 'atomic/molecules/ErrorBoundary';

import 'moment/locale/ru';

moment.locale('ru');

storageTokenSyncStart();

function App() {
  return (
    <ConfigProvider locale={ruRU}>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <Provider store={store}>
          <ConnectedRouter history={history}>
            <Layout>
              <ErrorBoundary>
                <RouterRoutes />
              </ErrorBoundary>
            </Layout>
          </ConnectedRouter>
        </Provider>
      </ThemeProvider>
    </ConfigProvider>
  );
}

export default App;
