import { LoadingActionTypes, LoadingStateType } from './types';

const initialState: LoadingStateType = {};

export default function (state = initialState, action: any): LoadingStateType {
  switch (action.type) {
    case LoadingActionTypes.SET_LOADING:
      return {
        ...state,
        ...{ [action.payload.key]: true },
      };
    case LoadingActionTypes.REMOVE_LOADING:
      return {
        ...state,
        ...{ [action.payload.key]: false },
      };
    default:
      return state;
  }
}
