import { ErrorsActionTypes, ErrorType } from './types';

export const setErrorsWatcher = (payload: {
  errorActionTypes: string | string[];
  successActionTypes?: string | string[];
  formKey: string;
}) => ({
  type: ErrorsActionTypes.SET_FORM_ERROR_WATCHER,
  payload,
});

export const cancelerrorsWatcher = (payload: { formKey: string }) => ({
  type: ErrorsActionTypes.CANCEL_FORM_ERROR_WATCHER,
  payload,
});

export const setErrors = (payload: { formKey: string; error?: ErrorType }) => ({
  type: ErrorsActionTypes.SET_ERRORS,
  payload,
});

export const removeErrors = (formKey: string) => ({
  type: ErrorsActionTypes.REMOVE_ERRORS,
  payload: { formKey },
});
