import axios from 'axios';

export const removeContent = (response: string) => {
  try {
    const responseParsed = JSON.parse(response);

    if (responseParsed.error) {
      return responseParsed.error;
    }

    return 'content' in responseParsed ? responseParsed.content : responseParsed;
  } catch {
    return response;
  }
};

export const appendValues = (values: Object) => (response: Object) => ({ ...response, ...values });

export const responseDefaultTransforms = [
  removeContent,
  ...(Array.isArray(axios.defaults.transformResponse) ? axios.defaults.transformResponse : []),
];
