import { call } from 'redux-saga/effects';

interface Meta {
  callback: Function;
}

// Meta middleware for invoke callback function.
export default function* metaCallbackSaga(meta: Meta) {
  if (!meta.callback) {
    return;
  }

  if (typeof meta.callback !== 'function') {
    throw new Error('Callback must be a function');
  }

  // @ts-ignore
  yield call(meta.callback);
}
