import { UniversalActionTypes } from './types';

import { CallApi } from '../callApiSaga';

const { callApiAction } = CallApi;

export const clearUniversal = (id?: string) => ({
  type: UniversalActionTypes.CLEAR_UNIVERSAL,
  payload: id,
});

export const getUniversal = ({
  key,
  request,
  callback,
  lazy,
}: {
  key: string;
  request: Function;
  callback?: Function;
  lazy?: boolean;
}) =>
  callApiAction({
    key,
    api: () => request(),
    types: [
      UniversalActionTypes.GET_UNIVERSAL_REQUEST,
      {
        type: UniversalActionTypes.GET_UNIVERSAL_SUCCESS,
        payload: (props: any) => {
          if (callback) {
            callback(props);
          }

          return props;
        },
        meta: {
          lazy,
        },
      },
      UniversalActionTypes.GET_UNIVERSAL_FAILURE,
    ],
  });

export const deleteUniversal = ({
  key,
  request,
  callback,
  callbackError,
}: {
  key: string;
  request: Function;
  callback?: Function;
  callbackError?: Function;
}) =>
  callApiAction({
    key,
    api: () => request(),
    types: [
      UniversalActionTypes.DELETE_UNIVERSAL_REQUEST,
      {
        type: UniversalActionTypes.DELETE_UNIVERSAL_SUCCESS,
        meta: {
          callback,
        },
      },
      {
        type: UniversalActionTypes.DELETE_UNIVERSAL_FAILURE,
        meta: {
          callback: callbackError,
        },
      },
    ],
  });
