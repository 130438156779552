import { PaginationType } from 'modules/api/types';

const moduleName = '@@universal';

export type UniversalStateType = {
  [key: string]: {
    items: any[];
    paginator: PaginationType;
    filter?: Object;
    notFoundItems?: number[];
  };
};

export enum UniversalQueryType {
  Update = 'update',
  Create = 'create',
}

export const UniversalActionTypes = {
  GET_UNIVERSAL_REQUEST: `${moduleName}/GET_UNIVERSAL_REQUEST`,
  GET_UNIVERSAL_SUCCESS: `${moduleName}/GET_UNIVERSAL_SUCCESS`,
  GET_UNIVERSAL_FAILURE: `${moduleName}/GET_UNIVERSAL_FAILURE`,

  DELETE_UNIVERSAL_REQUEST: `${moduleName}/GET_UNIVERSAL_REQUEST`,
  DELETE_UNIVERSAL_SUCCESS: `${moduleName}/GET_UNIVERSAL_SUCCESS`,
  DELETE_UNIVERSAL_FAILURE: `${moduleName}/GET_UNIVERSAL_FAILURE`,

  UNIVERSAL_SUBMIT_REQUEST: `${moduleName}/UNIVERSAL_SUBMIT_REQUEST`,
  UNIVERSAL_SUBMIT_SUCCESS: `${moduleName}/UNIVERSAL_SUBMIT_SUCCESS`,
  UNIVERSAL_SUBMIT_FAILURE: `${moduleName}/UNIVERSAL_SUBMIT_FAILURE`,

  MUTATE_UNIVERSAL: `${moduleName}/MUTATE_UNIVERSAL`,

  CLEAR_UNIVERSAL: `${moduleName}/CLEAR_UNIVERSAL`,
};
