import * as api from 'modules/api';

import {
  AuthActionTypes,
  ChangePasswordFormDataType,
  LoginType,
  TokenType,
  UserType,
} from './types';

import { CallApi } from '../callApiSaga';

import { normalizeUser } from './utils';

const { callApiAction } = CallApi;

export interface LoginAction {
  user: UserType;
}
export const setUser = (payload: UserType) => ({
  type: AuthActionTypes.SET_USER,
  payload,
});

export const setToken = (payload: TokenType) => ({
  type: AuthActionTypes.SET_TOKEN,
  payload,
});

export const login = (payload: UserType) => ({
  type: AuthActionTypes.LOGIN,
  payload,
});

export const logout = () => ({
  type: AuthActionTypes.LOGOUT,
});

export const refreshMe = () =>
  callApiAction({
    api: () => api.auth.getMe(),
    types: [
      AuthActionTypes.REFRESH_ME_REQUEST,
      {
        type: AuthActionTypes.REFRESH_ME_SUCCESS,
        payload: normalizeUser,
      },
      AuthActionTypes.REFRESH_ME_FAILURE,
    ],
  });

export const getCode = ({ formData, callback }: { formData: LoginType; callback?: Function }) =>
  callApiAction({
    key: 'getCode',
    api: () => api.auth.login({ formData }),
    types: [
      AuthActionTypes.GET_CODE_REQUEST,
      {
        type: AuthActionTypes.GET_CODE_SUCCESS,
        meta: {
          callback,
        },
      },
      {
        type: AuthActionTypes.GET_CODE_FAILURE,
        meta: {
          notification: false,
        },
      },
    ],
  });

export const checkPhoneCode = ({ code, uuid }: { code: string; uuid: string }) =>
  callApiAction({
    key: 'checkPhoneCode',
    api: () => api.auth.checkPhoneCode({ code, uuid }),
    types: [
      AuthActionTypes.CHECK_PHONE_CODE_REQUEST,
      AuthActionTypes.CHECK_PHONE_CODE_SUCCESS,
      AuthActionTypes.CHECK_PHONE_CODE_FAILURE,
    ],
  });

export const changePassword = (formData: ChangePasswordFormDataType) =>
  callApiAction({
    key: 'changePassword',
    api: () => api.auth.changePassword(formData),
    types: [
      AuthActionTypes.CHANGE_PASSWORD_REQUEST,
      {
        type: AuthActionTypes.CHANGE_PASSWORD_SUCCESS,
        meta: {
          notification: {
            type: 'success',
            message: 'Пароль успешно изменен',
          },
        },
      },
      {
        type: AuthActionTypes.CHANGE_PASSWORD_FAILURE,
        meta: {
          notification: {
            type: 'error',
            message: 'CHANGE_PASSWORD_FAILURE',
          },
        },
      },
    ],
  });
