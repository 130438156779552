const moduleName = '@@successMessages';

export const SuccessesActionTypes = {
  SET_SUCCESSES: `${moduleName}/SET_SUCCESSES`,
  REMOVE_SUCCESSES: `${moduleName}/REMOVE_SUCCESSES`,
};

export type SuccessesStateType = { [key: string]: SuccessType };

export type SuccessType = {
  message: string;
};
