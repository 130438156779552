import React, { ReactNode } from 'react';
import * as Sentry from '@sentry/browser';
import { Button, Result } from 'antd';
import styled from 'styled-components';

const sentrySkippedErrors = ['ChunkLoadError'];

type Props = {
  errorComponent?: ReactNode;
};

type State = {
  error: Error | null;
  eventId?: string;
};

const ErrorBoundaryStyled = styled.div`
  text-align: center;
`;

class ErrorBoundary extends React.Component<Props, State> {
  state = {
    error: null,
    eventId: undefined,
  };

  componentDidCatch(error: Error | null, info: any) {
    this.setState({ error });

    const isSentrySkipped = error ? sentrySkippedErrors.includes(error.name) : false;

    if (!isSentrySkipped) {
      Sentry.withScope((scope) => {
        scope.setExtras(info);
        const eventId = Sentry.captureException(error);
        this.setState({ eventId });
      });
    }
  }

  render() {
    const { errorComponent } = this.props;
    const { error } = this.state;

    if (error) {
      //render fallback UI
      return (
        errorComponent || (
          <ErrorBoundaryStyled>
            <Result
              status="404"
              title="Что-то пошло не так"
              subTitle="На странице что-то пошло не так, перезагрузите её пожалуйста."
              extra={
                <Button
                  type="primary"
                  onClick={() => {
                    window.location.reload();
                  }}
                >
                  Перезагрузить страницу
                </Button>
              }
            />
          </ErrorBoundaryStyled>
        )
      );
    } else {
      //when there's not an error, render children untouched
      return this.props.children;
    }
  }
}

export default ErrorBoundary;
