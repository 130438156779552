import { ErrorsActionTypes, ErrorsStateType } from './types';

const initialState: ErrorsStateType = {};

export default function (state = initialState, action: any): ErrorsStateType {
  switch (action.type) {
    case ErrorsActionTypes.CANCEL_FORM_ERROR_WATCHER:
      return {
        ...state,
        ...{ [action.payload.formKey]: undefined },
      };
    case ErrorsActionTypes.SET_ERRORS:
      return {
        ...state,
        ...{ [action.payload.formKey]: action.payload.error },
      };
    case ErrorsActionTypes.REMOVE_ERRORS:
      return {
        ...state,
        ...{ [action.payload.formKey]: undefined },
      };
    default:
      return state;
  }
}
