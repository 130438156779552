import { put, takeEvery } from 'redux-saga/effects';

import { ActionType } from 'modules/meta/types';
import { UniversalQueryType } from 'modules/universal/types';

import { removeSuccesses, setSuccesses } from './actions';

const DEFAULT_MSG = 'Операция завершена успешно';

function* successSaga(action: ActionType<{ data: any }>) {
  if (action.meta?.key) {
    const isUpdate = action.meta.key.includes(UniversalQueryType.Update);

    yield put(
      setSuccesses({
        formKey: action.meta.key,
        message: {
          message: isUpdate ? 'Данные успешно обновлены' : DEFAULT_MSG,
        },
      })
    );
  }
}

function* failureSaga(action: ActionType<unknown>) {
  if (action.meta?.key) {
    yield put(removeSuccesses(action.meta.key));
  }
}

export function* successesSaga() {
  yield takeEvery((action: ActionType<unknown>) => /_SUCCESS$/.test(action.type), successSaga);
  yield takeEvery((action: ActionType<unknown>) => /_FAILURE$/.test(action.type), failureSaga);
}
