import { UniversalActionTypes, UniversalStateType } from './types';

const initialState: UniversalStateType = {};

export default function (state = initialState, action: any): UniversalStateType {
  switch (action.type) {
    case UniversalActionTypes.GET_UNIVERSAL_SUCCESS:
    case UniversalActionTypes.MUTATE_UNIVERSAL:
      return {
        ...state,
        ...{
          [action.meta.key]: action.meta.lazy
            ? {
                ...action.payload,
                items: [...state[action.meta.key].items, ...action.payload.items],
              }
            : action.payload,
        },
      };
    case UniversalActionTypes.CLEAR_UNIVERSAL: {
      return action.payload
        ? { ...state, [action.payload]: undefined }
        : {
            ...initialState,
          };
    }

    default:
      return state;
  }
}
