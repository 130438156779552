import { message } from 'antd';
import { LOCATION_CHANGE, LocationChangeAction, push } from 'connected-react-router';
import moment from 'moment';
import { call, put, select, take, takeEvery, takeLatest } from 'redux-saga/effects';

import { ActionType } from 'modules/meta/types';
import sentry from 'modules/sentry';
import { AppState } from 'modules/store';

import {
  AuthActionTypes,
  AuthStateType,
  CheckPhoneCodeResponse,
  TokenType,
  UserType,
} from './types';

import { removeToken, setToken as setTokenApi } from '../api';

import { login, logout, refreshMe, setToken, setUser } from './actions';

// saga for login from saved token & user & store
function* autoLoginSaga() {
  const { token, isAuth, user }: AuthStateType = yield select((state: AppState) => state.auth);
  if (isAuth && token) {
    setTokenApi(token.accessToken);
    yield put(refreshMe());
  }

  // Log for sentry;
  if (user) {
    yield sentry.setUser({ id: user.id, username: user.username });
  }
}

function* logoutSaga() {
  removeToken();

  // Redirect to login page
  yield put(push('/login'));

  // Log for sentry;
  yield sentry.removeUser();
}

function* setTokenSaga({ payload }: { type: string; payload: TokenType }) {
  yield setTokenApi(payload.accessToken);
}

function* loginSaga(action: { type: string; payload: CheckPhoneCodeResponse }) {
  const { user, accessToken, accessTokenExpiredAt } = action.payload;
  yield put(setToken({ accessToken, accessTokenExpiredAt }));
  yield put(login(user));
  // Log for sentry;
  yield sentry.setUser({ id: user.id, username: user.username });
}

function* failureSaga(action: ActionType<{ status: number }>) {
  if (action?.payload?.status === 401) {
    yield put(logout());
  }
}

function* setUserSaga(action: { type: string; payload: UserType }) {
  // Проверяем когда истечен срок пароля, и если до срока осталось 14 дней (2 недели),
  // то дает пользователю понять что пора изменить пароль.
  if (action.payload.passwordExpiredAt) {
    action.payload['_shouldChangePassword'] =
      action.payload.passwordExpiredAt < moment().add(2, 'week').unix() * 1000;
  }

  // Проверка на присутствие хотя бы одного права;
  if (!action.payload.tmpPassword && !Object.keys(action.payload.permissions).length) {
    yield put(logout());
    yield message.error('У вас недостаточно прав');

    return;
  }

  yield put(setUser(action.payload));

  // Проверим веременный ли это пароль, и если да, то отправляем на форму изменение пароля;
  if (action.payload.tmpPassword) {
    yield put(push('/user/password'));
  }
}

function* tmpPasswordSaga(action: LocationChangeAction) {
  const user: UserType = yield select((state: AppState) => state.auth.user);
  if (user && user.tmpPassword && action.payload.location.pathname !== '/user/password') {
    yield put(push('/user/password'));
  }
}

// После смены пароля необходимо обновить данные пользователя и отредиректить на главную страницу;
function* changePasswordSaga(action: ActionType<TokenType & { user: UserType }>) {
  if (action.payload) {
    yield put(
      setToken({
        accessToken: action.payload.accessToken,
        accessTokenExpiredAt: action.payload.accessTokenExpiredAt,
      })
    );
    yield put(setUser(action.payload.user));
  }

  yield put(refreshMe());
  yield take(AuthActionTypes.REFRESH_ME_SUCCESS);
  yield put(push('/'));
}

export function* authSaga() {
  yield takeLatest(LOCATION_CHANGE, tmpPasswordSaga);
  yield takeLatest(AuthActionTypes.SET_TOKEN, setTokenSaga);
  yield takeEvery(AuthActionTypes.LOGOUT, logoutSaga);
  yield takeEvery(AuthActionTypes.CHECK_PHONE_CODE_SUCCESS, loginSaga);
  yield takeEvery((action: ActionType<unknown>) => /_FAILURE$/.test(action.type), failureSaga);
  yield takeEvery(AuthActionTypes.CHANGE_PASSWORD_SUCCESS, changePasswordSaga);

  yield takeEvery(
    [AuthActionTypes.REFRESH_ME_SUCCESS, AuthActionTypes.LOGIN, AuthActionTypes.GET_ME_SUCCESS],
    setUserSaga
  );

  yield call(autoLoginSaga);
}

export default authSaga;
