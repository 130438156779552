import { ChangePasswordFormDataType, LoginType } from 'modules/auth/types';
import { request } from 'modules/api';

import { normalizeUser } from './utils';

export default {
  login: async ({ formData }: { formData: LoginType }) =>
    await request.post('/auth/sign-in', formData),

  getMe: async () => await request.get('/user/current'),

  checkPhoneCode: async ({ code, uuid }: { code: string; uuid: string }) => {
    const response = await request.post('/auth/sign-in/confirm', { code, uuid });
    response.data.user = normalizeUser(response.data.user);

    return response;
  },

  changePassword: async (formData: ChangePasswordFormDataType) =>
    await request.put('/user/password', formData),
};
