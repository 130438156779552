import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import persistState from 'redux-localstorage';
import createSagaMiddleware from 'redux-saga';
import { fork } from 'redux-saga/effects';

import baseSaga from './baseSaga';
import { createRootReducer } from './reducers';

function customSlicer() {
  return () => (subset: any) => {
    const {
      auth: { user, token, isAuth },
    } = subset;

    return { auth: { user, token, isAuth } };
  };
}

export const history = createBrowserHistory();

export function* rootSaga() {
  yield fork(baseSaga, {});
}

const sagaMiddleware = createSagaMiddleware();

const middlewares: any = [sagaMiddleware, routerMiddleware(history)];

export const rootReducer = createRootReducer(history);

const store = createStore(
  rootReducer,
  composeWithDevTools(
    applyMiddleware(...middlewares),
    // @ts-ignore
    persistState(null, { slicer: customSlicer() })
  )
);

sagaMiddleware.run(rootSaga);

export type AppState = ReturnType<typeof rootReducer>;

export default store;
