import { ReactElement } from 'react';

import { EmployeesTypes } from 'modules/employee/types';

export type ResourcesType = ResourceType[];

export type ResourceType = {
  key: string;
  url: string;
  apiAxiosInstanse?: string;
};

export type RouterConfigType = {
  [index: string]: RouterItemType;
};

export type Places = 'main' | 'footer';

export type RouterItemType = {
  location?: string;
  path?: string;
  exact?: boolean;
  showAside?: boolean;
  Icon?: ReactElement;
  resources?: ResourceType[];
  place?: Places[];
  name?: string;
  permissions?: EmployeesTypes.Subject[];
  notScrollToTop?: boolean;
  childrens?: {
    [key: string]: RouterItemType;
  };
};

export type RoutesType = {
  [key: string]: Function;
};

export type RelocateToRoutePayload = {
  to: string;
  after: string | string[];
  cancel?: string;
  waitForever?: boolean;
  payload?({
    to,
    after,
    cancel,
  }: {
    to: string;
    cancel: Function;
    after: {
      type: string;
      payload?: any;
    };
  }): void;
};

export type BreadcrumbsType = { label: string | number; to?: string }[];

export interface RouterStateType {
  breadcrumbs: BreadcrumbsType;
}

const moduleName = '@@router';

export const RouterActionTypes = {
  RELOCATE_TO_ROUTE: `${moduleName}/RELOCATE_TO_ROUTE`,
  RELOCATE_TO_ROUTE_CANCEL: `${moduleName}/RELOCATE_TO_ROUTE_CANCEL`,
  SET_BREADCRUMBS: `${moduleName}/SET_BREADCRUMBS`,
};
