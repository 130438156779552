import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  b, strong {
    color: rgba(0, 0, 0, 0.85);
      font-weight: 500;
  }
  .ant-form .ant-form-item {
    display: block;
  }
  .ant-dropdown-menu-item a {
    color: rgba(0, 0, 0, 0.85);
  }
  .ant-form-item-explain {
    margin-top: 3px;
  }
  .break-all {
    word-break: break-all;
  }
  .ant-dropdown-menu-item-disabled.ant-dropdown-menu-item > .anticon + span > a {
    color: rgba(0, 0, 0, 0.25);
    background-color: #fff;
    cursor: not-allowed;
  }

  .ant-tabs-tab-btn a {
    color: rgba(0, 0, 0, 0.85);
  }

  .ant-tabs-tab-active a {
    color: #7ac143;
  }
`;
