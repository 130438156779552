/** Функция для рекурсивого обновления элементов таблицы Universal после события update */
export const collectionUpdateMapper = (action: any) => (item: any) => {
  if (item.id !== action.payload.id) {
    if (item.children?.some((child: any) => child.id === action.payload.id)) {
      item.children = item.children.map(collectionUpdateMapper(action));
    }

    return item;
  }

  return action.payload;
};

/** Функция для получения порядкого номера строки с учетом пагинации */
export const getRowNumber = (current: number, step: number, index: number) =>
  (current - 1) * step + index + 1;
