import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LockOutlined, LogoutOutlined } from '@ant-design/icons';
import { Button, Dropdown, Layout as AntdLayout, Menu } from 'antd';
import { styled } from 'config/theme';
import { push } from 'connected-react-router';

import { logout } from 'modules/auth/actions';
import { AppState } from 'modules/store';

const { Header: AntdHeader } = AntdLayout;

type Props = {
  className?: string;
};

const HeaderComponent = ({ className }: Props) => {
  const auth = useSelector((state: AppState) => state.auth);
  const dispatch = useDispatch();

  function logoutHandler() {
    dispatch(logout());
  }

  function changePasswordHandler() {
    dispatch(push('/user/password'));
  }

  const menu = (
    <Menu>
      <Menu.Item icon={<LockOutlined />} onClick={changePasswordHandler}>
        Сменить пароль
      </Menu.Item>
      <Menu.Item icon={<LogoutOutlined />} onClick={logoutHandler}>
        Выйти
      </Menu.Item>
    </Menu>
  );

  return (
    <AntdHeader className={className}>
      <Button.Group>
        <Dropdown.Button overlay={menu}>{auth.user?.username}</Dropdown.Button>
      </Button.Group>
    </AntdHeader>
  );
};

export const Header = styled(HeaderComponent)`
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-bottom: 1px solid #f9f9f9;
  padding: 0 50px 0 0;
  .trigger {
    font-size: 18px;
    line-height: 64px;
    padding: 0 24px;
    cursor: pointer;
    transition: color 0.3s;
  }
  .zoom-select {
    margin-right: 15px;
  }
`;
