import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, RouteProps } from 'react-router-dom';

import { AuthStateType } from 'modules/auth/types';
import { EmployeesTypes } from 'modules/employee/types';
import { AppState } from 'modules/store';

interface Props {
  redirectRoute?: string;
  path?: RouteProps['path'];
  exact?: RouteProps['exact'];
  component?: RouteProps['component'];
  onDenied?({
    permissions,
    requiredPermissions,
    path,
  }: {
    permissions: EmployeesTypes.Subject[];
    requiredPermissions: EmployeesTypes.Subject[];
    path?: any;
  }): Function;
  hasAccess?: boolean;
  permissions?: EmployeesTypes.Subject[];
}

/** Настройки редиректа при отсутствии прав в конкретный раздел */
export const redirectByRole = {
  guest: '/login',
  user: '/',
  ARTICLE: '/dashboard',
  BOOKING: '/dashboard',
  COMPANY: '/dashboard',
  GEO: '/dashboard',
  CONFIG: '/dashboard',
  SEGMENT: '/dashboard',
  CUSTOMER: '/dashboard',
  QUALIFIER: '/dashboard',
  ENTITY_LOG: '/dashboard',
  EMPLOYEE_ACL: '/dashboard',
  EMPLOYEE_MANAGER: '/dashboard',
  SPECIALIST: '/dashboard',
  DICTIONARY: '/dashboard',
  MODERATION: '/dashboard',
  REVIEW: '/dashboard',
  TREATMENT: '/dashboard',
  MODERATION_STATISTICS: '/dashboard',
  WEB_SEO: '/dashboard',
  DOCUMENT: '/dashboard',
  COMMENT: '/dashboard',
  PROMOTION: '/dashboard',
  SUPPORT: '/dashboard',
  PAYMENT: '/dashboard',
  ASTRO: '/dashboard',
  PENETRATION_CUSTOMER: '/dashboard',
  FAQ: '/dashboard',
  BANNER: '/dashboard',
  CUSTOMER_PASSWORD: '/dashboard',
  ASTRO_PACK: '/dashboard',
  CONVERSATION: '/dashboard',
};

export function getRedirectPathByRole(userRole?: EmployeesTypes.Subject) {
  // TODO: Сделать '/' по дефолту, и убрать этот спаггети код;
  return redirectByRole[userRole || 'guest'];
}

const RoleRoute: FC<Props> = ({ onDenied, redirectRoute = '/', permissions = [], ...rest }) => {
  const auth: AuthStateType = useSelector((state: AppState) => state.auth);
  const userPermissions = (auth.user?.permissions
    ? ['user', ...Object.keys(auth.user?.permissions)]
    : ['guest']) as EmployeesTypes.Subject[];

  let hasAccess = true;

  if (!(auth.isAuth && rest.path === '/')) {
    hasAccess = permissions.some((item) => userPermissions.includes(item));

    const meta = {
      permissions: userPermissions,
      requiredPermissions: permissions,
      path: rest.path,
      status: hasAccess ? 'Allowed' : 'Denied',
    };

    const metaDebug = {
      permissions: JSON.stringify(userPermissions),
      requiredPermissions: JSON.stringify(permissions),
      path: rest.path,
      status: hasAccess ? 'Allowed' : 'Denied',
    };

    if (onDenied) {
      console.log(metaDebug);
      onDenied(meta);
    }
  }

  return hasAccess ? (
    <Route {...rest} />
  ) : (
    <Redirect to={getRedirectPathByRole(userPermissions[0])} />
  );
};

export default RoleRoute;
