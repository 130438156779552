import { EmployeesTypes } from 'modules/employee/types';

import { Permissions, UserResponseType } from './types';

export const normalizeUser = (user: UserResponseType) => {
  const userCopy = JSON.parse(JSON.stringify(user)) as UserResponseType;

  return userCopy;
};

export const checkPermission = ({
  permissions,
  requiredPermissions = ['guest'],
  permissionType,
}: {
  permissions: Permissions;
  requiredPermissions?: EmployeesTypes.Subject[];
  permissionType: 'read' | 'edit' | 'create' | 'delete';
}) => {
  return Object.entries(permissions).find(([name, options]: any) => {
    if (requiredPermissions.includes(name)) {
      return options.access[permissionType];
    }

    return false;
  });
};

export function storageTokenSyncStart() {
  const STORAGE_TEST_MESSAGE = '__storage_test__';

  window.addEventListener('storage', (event) => {
    const { newValue: newStringified, oldValue: oldStringified } = event;

    /**
     * redux-localstorage при входе проверяет доступность localStorage API согласно примеру в документации.
     * https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API#testing_for_availability
     */
    if (newStringified === STORAGE_TEST_MESSAGE || oldStringified === STORAGE_TEST_MESSAGE) {
      return;
    }

    try {
      const newValue = JSON.parse(newStringified || '{}').auth || {};
      const oldValue = JSON.parse(oldStringified || '{}').auth || {};

      const isLogout =
        oldValue.user && !newValue.user && oldValue.isAuth && !newValue.isAuth && !newValue.token;

      const isLoggedIn =
        !oldValue.user && newValue.user && !oldValue.isAuth && newValue.isAuth && !!newValue.token;

      if (isLoggedIn || isLogout) {
        window.location.reload();
      }
    } catch (error) {
      console.log(error);
    }
  });
}
