import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Layout as AntdLayout, Menu } from 'antd';
import { RELEASE_VERSION } from 'config/env';
import { Location } from 'history';

import { scrollToTop } from 'utils/DomHelpers';

import { checkPermission } from 'modules/auth/utils';
import { Permissions } from 'modules/auth/types';
import { RouterItemType } from 'modules/router/types';
import routerConfig from 'modules/router/router.config';

import { Version } from 'atomic/molecules/Version';

const { SubMenu } = Menu;
const { Sider } = AntdLayout;

type Props = {
  permissions?: Permissions;
  location: Location;
};

type State = {
  collapsed: boolean;
};

class Aside extends Component<Props, State> {
  state = {
    collapsed: localStorage.getItem('collapsed') === 'true',
  };

  // Если пользователь жмет в очередной раз на раздел на котором находится,
  // то смею предположить что-то пошло не так, и решением может являться
  // перезагрузка страницы в надежде на то что это поможет.
  shouldReload = (path: string) => {
    if (this.props.location.pathname === path) {
      document.location.replace(this.props.location.pathname);
    } else {
      scrollToTop();
    }
  };

  generateNavigation = (collection: any) => {
    const { permissions } = this.props;

    return collection.map((item: RouterItemType[]) => {
      const [routeName, routeConfig] = item;

      if (!permissions) {
        return false;
      }

      const hasAccess = checkPermission({
        permissions,
        requiredPermissions: routeConfig.permissions,
        permissionType: 'read',
      });

      if (!hasAccess) {
        return false;
      }

      if (routeConfig.showAside && !routeConfig.childrens) {
        return (
          <Menu.Item key={routeConfig.path}>
            {routeConfig.path ? (
              <Link
                to={routeConfig.path}
                onClick={() => {
                  if (routeConfig.path) {
                    this.shouldReload(routeConfig.path);
                  } else {
                    scrollToTop();
                  }
                }}
              >
                {routeConfig.Icon ? routeConfig.Icon : null}
                <span>{routeConfig.name}</span>
              </Link>
            ) : (
              <span>{routeConfig.name}</span>
            )}
          </Menu.Item>
        );
      }

      if (routeConfig.showAside && routeConfig.childrens) {
        return (
          <SubMenu
            key={`${routeName}`}
            title={
              <>
                {routeConfig.Icon ? routeConfig.Icon : null}
                <span>{routeConfig.name}</span>
              </>
            }
          >
            {this.generateNavigation(Object.entries(routeConfig.childrens))}
          </SubMenu>
        );
      }

      return undefined;
    });
  };

  getCurrentLocation = (navigation: any): { selectedKey?: string[]; openKey?: string[] } => {
    const { location } = this.props;
    const result: { selectedKey?: string[]; openKey?: string[] } = {};
    const recursiveSearch = (collection: any, parent: any) =>
      collection.forEach((item: RouterItemType[]) => {
        if (item[1].childrens) {
          return recursiveSearch(Object.entries(item[1].childrens), item);
        }

        if (item[1].path === location.pathname) {
          result.selectedKey = [item[1].path];
          if (parent) {
            result.openKey = [parent[0]];
          }
        }

        return null;
      });

    recursiveSearch(Object.entries(navigation), undefined);

    return result;
  };

  collapseHandler = (value: boolean) => {
    this.setState({ collapsed: value });
    localStorage.setItem('collapsed', value.toString());
  };

  render() {
    const { collapsed } = this.state;
    const currentLocation = this.getCurrentLocation(routerConfig);

    return (
      <Sider
        theme="light"
        width="250"
        collapsible
        collapsed={collapsed}
        onCollapse={this.collapseHandler}
        // TODO: Move to styles
        style={{ borderRight: '1px solid #f9f9f9' }}
      >
        {!collapsed && (
          <a href="/" className="logo">
            <img src="/logo.svg" alt="logo" />
          </a>
        )}
        <Menu
          theme="light"
          mode="inline"
          defaultSelectedKeys={currentLocation.selectedKey}
          defaultOpenKeys={currentLocation.openKey}
          selectedKeys={currentLocation.selectedKey}
          style={{ borderRight: 'none' }}
        >
          {this.generateNavigation(Object.entries(routerConfig))}
        </Menu>
        {!collapsed && <Version>{RELEASE_VERSION || '{RELEASE_VERSION}'}</Version>}
      </Sider>
    );
  }
}

export default Aside;
