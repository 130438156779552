import React, { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Alert, Layout as AntdLayout } from 'antd';
import { styled } from 'config/theme';

import { AppState } from 'modules/store';

import Aside from 'atomic/organisms/layout/Aside';
import { Header } from 'atomic/organisms/layout/Header';

export interface Props {
  children?: ReactNode;
  className?: string;
}

const LayoutComponent = ({ children, className }: Props) => {
  const isAuth = useSelector((state: AppState) => state.auth.isAuth);
  const user = useSelector((state: AppState) => state.auth.user);
  const location = useSelector((state: AppState) => state.router.location);

  return (
    <AntdLayout className={className}>
      {isAuth && !user?.tmpPassword && (
        <Aside location={location} permissions={user?.permissions} />
      )}
      <AntdLayout>
        {isAuth && <Header />}
        {user?._shouldChangePassword && (
          <Link to="/user/password">
            <Alert
              message="Для дальнейшего использования сайта вам необходимо сменить пароль, пожалуйста сделайте это как можно скорее."
              banner
            />
          </Link>
        )}
        {children}
      </AntdLayout>
    </AntdLayout>
  );
};

export const Layout = styled(LayoutComponent)`
  display: flex;
  min-height: 100vh;
  .logo {
    display: block;
    width: 160px;
    margin: 15px auto;
    transition: opacity 0.15s;
    &:hover {
      opacity: 0.7;
    }
  }
`;
