import { LOCATION_CHANGE, push } from 'connected-react-router';
import queryString from 'query-string';
import rEquals from 'ramda/src/equals';
import rReject from 'ramda/src/reject';
import { cancel, put, race, take, takeEvery } from 'redux-saga/effects';

import { RelocateToRoutePayload, RouterActionTypes, RoutesType } from './types';

const sagas: { [key: string]: any } = {};
let prevLocation = '';
let routesData: RoutesType;

export function* relocateSaga() {
  while (true) {
    const {
      payload: {
        after,
        payload,
        cancel = RouterActionTypes.RELOCATE_TO_ROUTE_CANCEL,
        to,
        waitForever = false,
      },
    }: { payload: RelocateToRoutePayload } = yield take(RouterActionTypes.RELOCATE_TO_ROUTE);

    yield relocateSagaWatcher({
      afterActionType: after,
      cancelActionType: cancel,
      payload,
      to,
      waitForever,
    });
  }
}

function* relocateSagaWatcher({
  afterActionType,
  cancelActionType,
  payload,
  to,
  waitForever,
}: {
  waitForever: boolean;
  afterActionType: string | string[];
  cancelActionType: string;
  payload?({
    to,
    after,
    cancel,
  }: {
    to: string;
    cancel: Function;
    after: {
      type: string;
      payload?: any;
    };
  }): void;
  to: string;
}) {
  const cancelFunc = function* () {
    yield cancel();
  };

  while (true) {
    const { afterAction, cancelAction } = yield race({
      afterAction: take(afterActionType),
      cancelAction: take(cancelActionType),
    });

    if (afterAction) {
      if (payload) {
        yield payload({
          after: afterAction,
          to,
          cancel: cancelFunc,
        });
      } else {
        yield put(push(to));
      }

      if (!waitForever) {
        yield cancel();
      }
    }

    if (cancelAction) {
      yield cancel();
    }
  }
}

export function* routerSagaWatcher(data: any) {
  const {
    payload: {
      location: { pathname, search },
    },
  } = data;
  yield proceedRoute({ pathname, search, routes: routesData });
}

export function* routerSaga(routes: RoutesType) {
  routesData = routes;
  yield takeEvery(LOCATION_CHANGE, routerSagaWatcher);
}

function* proceedRoute({
  pathname,
  routes,
  search,
}: {
  pathname: string;
  routes: RoutesType;
  search: string;
}) {
  const currentLocation = pathname + search;

  if (prevLocation !== currentLocation) {
    for (let key in routes) {
      const isEqual = isPathEqual({
        pathname,
        route: key,
      });

      if (sagas[key]) {
        yield cancel(sagas[key]);
        sagas[key] = undefined;
      }

      if (isEqual) {
        sagas[key] = yield routes[key]({
          // @ts-ignore
          params: rReject(rEquals(''))(queryString.parse(search)),
          ...getDynamicPathData({ pathname, route: key }),
        });
      }
    }

    prevLocation = currentLocation;
  }
}

const isPathEqual = ({ pathname, route }: { pathname: string; route: string }): boolean => {
  const dynamicPathsKeys = route
    .split('/')
    .map((path, i) => path.includes(':') && i)
    .filter(Number);

  const pathnameArr = pathname.split('/');
  const routeArr = route.split('/');

  if (pathnameArr.length === routeArr.length) {
    let isEqual = true;
    pathnameArr.forEach((item, i) => {
      if (item !== routeArr[i] && !dynamicPathsKeys.includes(i)) {
        isEqual = false;

        return;
      }
    });

    return isEqual;
  } else {
    return false;
  }
};

const getDynamicPathData = ({ pathname, route }: { pathname: string; route: string }): Object => {
  let data: { [key: string]: string } = {};
  const pathnameArr = pathname.split('/');
  route.split('/').forEach((path, i) => {
    if (path.includes(':')) {
      data[path.split(':')[1]] = pathnameArr[i];
    }
  });

  return data;
};
