import { addBreadcrumb, Severity } from '@sentry/browser';
import { APP_ENV, SENTRY_DSN } from 'config/env';
import { take } from 'redux-saga/effects';

import sentry from './index';

export function* sentrySaga() {
  if (SENTRY_DSN) {
    sentry.init({ SENTRY_DSN, environment: APP_ENV });
  }

  while (true) {
    const action = yield take('*');
    addBreadcrumb({
      message: action.type,
      category: 'Action',
      data: action,
      level: !action.error ? Severity.Info : Severity.Error,
    });
  }
}
