import { AuthActionTypes, AuthStateType } from './types';

const initialState: AuthStateType = {
  user: null,
  token: undefined,
  code: null,
  isAuth: false,
};

export default function (state = initialState, action: any): AuthStateType {
  switch (action.type) {
    case '@@INIT':
      return {
        ...state,
        code: null,
      };

    case AuthActionTypes.GET_CODE_REQUEST:
      return {
        ...state,
        code: null,
      };

    case AuthActionTypes.GET_CODE_SUCCESS:
      return {
        ...state,
        code: action.payload,
      };

    case AuthActionTypes.SET_TOKEN:
      return {
        ...state,
        token: action.payload,
      };

    case AuthActionTypes.LOGOUT:
      return {
        ...state,
        ...initialState,
      };

    case AuthActionTypes.REFRESH_ME_REQUEST:
      return {
        ...state,
      };

    case AuthActionTypes.SET_USER:
      return {
        ...state,
        isAuth: true,
        user: action.payload,
      };

    case AuthActionTypes.CHECK_PHONE_CODE_SUCCESS:
      return {
        ...state,
        code: null,
      };

    default:
      return state;
  }
}
