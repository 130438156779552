import { connectRouter } from 'connected-react-router';
import { History } from 'history';
import { combineReducers } from 'redux';

import auth from 'modules/auth/reducer';
import errors from 'modules/errors/reducer';
import loading from 'modules/loading/reducer';
import router from 'modules/router/reducer';
import successes from 'modules/successes/reducer';
import universal from 'modules/universal/reducer';

export const createRootReducer = (history: History) =>
  combineReducers({
    router: connectRouter(history),
    app: combineReducers({ errors, loading, router, successes }),
    auth,
    universal,
  });
