import { SuccessesActionTypes, SuccessType } from './types';

export const setSuccesses = (payload: { formKey: string; message?: SuccessType }) => ({
  type: SuccessesActionTypes.SET_SUCCESSES,
  payload,
});

export const removeSuccesses = (formKey: string) => ({
  type: SuccessesActionTypes.REMOVE_SUCCESSES,
  payload: { formKey },
});
