import React, { lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router';

import { RouterConfigType } from './types';

import RoleRoute from './RoleRoute';
import routerConfig from './router.config';

const CustomLoadableComponents: any = {};

const setLoader = (config: RouterConfigType) => {
  for (const key in config) {
    const value = config[key];
    if (!value.childrens) {
      if (value.location) {
        CustomLoadableComponents[key] = lazy(() => import(`atomic/pages/${value.location}`));
      }
    } else {
      setLoader(value.childrens);
    }
  }
};

setLoader(routerConfig);

const getFlatRouterConfig = (routerConfig: RouterConfigType) => {
  let config = { ...routerConfig };
  for (const key in routerConfig) {
    if (routerConfig.hasOwnProperty(key)) {
      const element = routerConfig[key];
      if (element.childrens) {
        config = { ...config, ...element.childrens };
        delete config[key];
      }
    }
  }

  return config;
};

export interface IRouterRoutes {}

export const RouterRoutes = (props: IRouterRoutes) => {
  const routerConfigFlat = getFlatRouterConfig(routerConfig);

  return (
    <>
      <Suspense fallback={<div></div>}>
        <Switch>
          {Object.keys(CustomLoadableComponents).map((name: string) => {
            const TagName = CustomLoadableComponents[name];
            if (!routerConfigFlat[name]) {
              return false;
            }

            const { path, exact, permissions } = routerConfigFlat[name];

            return (
              path && (
                <RoleRoute path={path} key={name} exact={exact} permissions={permissions}>
                  <TagName {...props} />
                </RoleRoute>
              )
            );
          })}
          <Route component={() => <CustomLoadableComponents.NoMatch />} />
        </Switch>
      </Suspense>
    </>
  );
};
