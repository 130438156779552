import * as Sentry from '@sentry/browser';
import { BrowserTracing } from '@sentry/tracing';
import { AxiosError } from 'axios';
import { RELEASE_VERSION } from 'config/env';

const sentry = {
  blacklistResponseStatusCapture: [401, 404],

  init({ SENTRY_DSN, environment }: { SENTRY_DSN: string; environment: string }) {
    Sentry.init({
      dsn: SENTRY_DSN,
      environment,
      release: RELEASE_VERSION,
      integrations: [new BrowserTracing()],
      ignoreErrors: ['ResizeObserver loop limit exceeded'],
      tracesSampleRate: 0.3,
    });
  },

  setUser(user: { username: string; id: number }) {
    Sentry.setUser({ ...user, id: user.id.toString() });
  },

  removeUser() {
    Sentry.configureScope((scope) => scope.setUser(null));
  },

  captureException: (error: Object) => {
    Sentry.captureException(error);
  },

  errorResponseCapture: (error: AxiosError): void => {
    if (!error) {
      return;
    }

    if (
      error.response &&
      error.response.status >= 400 &&
      !sentry.blacklistResponseStatusCapture.includes(error.response.status)
    ) {
      Sentry.withScope((scope) => {
        scope.setExtra('network', error);
        Sentry.captureException(error);
      });
    }

    return;
  },
};

export default sentry;
