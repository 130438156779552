import { SuccessesActionTypes, SuccessesStateType } from './types';

const initialState: SuccessesStateType = {};

export default function (state = initialState, action: any): SuccessesStateType {
  switch (action.type) {
    case SuccessesActionTypes.SET_SUCCESSES:
      return {
        ...state,
        ...{ [action.payload.formKey]: action.payload.message },
      };

    case SuccessesActionTypes.REMOVE_SUCCESSES:
      return {
        ...state,
        ...{ [action.payload.formKey]: undefined },
      };

    default:
      return state;
  }
}
