import axios, { AxiosError, AxiosResponse } from 'axios';
import queryString from 'query-string';

import { API_URL } from '../../config/env';

import { responseDefaultTransforms } from './transforms';

const commonConfig = {
  baseURL: API_URL,
  paramsSerializer: function (params: Object) {
    return queryString.stringify(params, { arrayFormat: 'bracket', sort: false });
  },
  transformResponse: responseDefaultTransforms,
  // transformRequest: requestDefaultTransforms,
};

// Backend entrypoint
const instance = axios.create(commonConfig);

const handleSuccess = (response: AxiosResponse) => response;

const handleError = (error: AxiosError) => {
  console.log(error);

  return Promise.reject(error);
};

instance.interceptors.response.use(handleSuccess, handleError);

export const setToken = (token: string) => {
  instance.defaults.headers.common.Authorization = `Bearer ${token}`;
};

export const removeToken = () => {
  delete instance.defaults.headers.common.Authorization;
};

export const request = instance;
