import { setNotification } from './actions';
import errorNotificationSaga from './errorNotificationSaga';
import metaNotificationsSaga from './metaNotificationsSaga';
import { Notification } from './Notification';

const NotificationModule = {
  errorNotificationSaga,
  metaNotificationsSaga,
  setNotification,
  Notification,
};

export default NotificationModule;
