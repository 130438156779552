import { EmployeesTypes } from 'modules/employee/types';

export type UserRole = 'guest' | 'ROLE_EMPLOYEE';

export interface CheckPhoneCodeResponse extends TokenType {
  user: UserType;
}

export interface UserResponseType {
  id: number;
  username: string;
  roles: UserRole[];
  permissions: EmployeesTypes.Permission[];
  updatedAt: number;
}

export type Permissions = {
  [subject in EmployeesTypes.Subject]: {
    name: EmployeesTypes.Subject;
    access: {
      create: boolean;
      edit: boolean;
      read: boolean;
      delete: boolean;
    };
  };
};

export interface UserType {
  id: number;
  username: string;
  roles: UserRole[];
  permissions: Permissions;
  passwordExpiredAt: number | null;
  updatedAt: number;
  tmpPassword: boolean;
  _shouldChangePassword: boolean;
}

export type ChangePasswordFormDataType = {
  currentPassword: string;
  newPassword: string;
};

export interface AuthStateType {
  user: UserType | null;
  isAuth: boolean;
  token?: TokenType;
  code: CodeType | null;
}

export interface CodeType {
  uuid: string;
  phone: string;
  expiredAt: string;
}

export interface TokenType {
  accessToken: string;
  accessTokenExpiredAt: number;
}

export interface LoginType {
  username: string;
  password: string;
  code?: string;
}

export type ConfirmEmailType = {
  code: string;
};

const moduleName = '@@auth';

export const AuthActionTypes = {
  GET_ME_REQUEST: `${moduleName}/GET_ME_REQUEST`,
  GET_ME_SUCCESS: `${moduleName}/GET_ME_SUCCESS`,
  GET_ME_FAILURE: `${moduleName}/GET_ME_FAILURE`,

  REFRESH_ME_REQUEST: `${moduleName}/REFRESH_ME_REQUEST`,
  REFRESH_ME_SUCCESS: `${moduleName}/REFRESH_ME_SUCCESS`,
  REFRESH_ME_FAILURE: `${moduleName}/REFRESH_ME_FAILURE`,

  GET_CODE_REQUEST: `${moduleName}/GET_CODE_REQUEST`,
  GET_CODE_SUCCESS: `${moduleName}/GET_CODE_SUCCESS`,
  GET_CODE_FAILURE: `${moduleName}/GET_CODE_FAILURE`,

  CHECK_PHONE_REQUEST: `${moduleName}/CHECK_PHONE_REQUEST`,
  CHECK_PHONE_SUCCESS: `${moduleName}/CHECK_PHONE_SUCCESS`,
  CHECK_PHONE_FAILURE: `${moduleName}/CHECK_PHONE_FAILURE`,

  CHECK_PHONE_CODE_REQUEST: `${moduleName}/CHECK_PHONE_CODE_REQUEST`,
  CHECK_PHONE_CODE_SUCCESS: `${moduleName}/CHECK_PHONE_CODE_SUCCESS`,
  CHECK_PHONE_CODE_FAILURE: `${moduleName}/CHECK_PHONE_CODE_FAILURE`,

  LOGIN_FAILURE: `${moduleName}/LOGIN_FAILURE`,
  LOGIN: `${moduleName}/LOGIN`,
  LOGOUT: `${moduleName}/LOGOUT`,

  RESEND_PHONE_CODE_REQUEST: `${moduleName}/RESEND_PHONE_CODE_REQUEST`,
  RESEND_PHONE_CODE_SUCCESS: `${moduleName}/RESEND_PHONE_CODE_SUCCESS`,
  RESEND_PHONE_CODE_FAILURE: `${moduleName}/RESEND_PHONE_CODE_FAILURE`,

  CODE_CONFIRM_REQUEST: `${moduleName}/CODE_CONFIRM_REQUEST`,
  CODE_CONFIRM_SUCCESS: `${moduleName}/CODE_CONFIRM_SUCCESS`,
  CODE_CONFIRM_FAILURE: `${moduleName}/CODE_CONFIRM_FAILURE`,

  CHANGE_PASSWORD_REQUEST: `${moduleName}/CHANGE_PASSWORD_REQUEST`,
  CHANGE_PASSWORD_SUCCESS: `${moduleName}/CHANGE_PASSWORD_SUCCESS`,
  CHANGE_PASSWORD_FAILURE: `${moduleName}/CHANGE_PASSWORD_FAILURE`,

  SET_REGISTER_STEP: `${moduleName}/SET_REGISTER_STEP`,
  SET_USER: `${moduleName}/SET_USER`,
  SET_TOKEN: `${moduleName}/SET_TOKEN`,
  CHECK_PHONE_CODE_SUBMIT: `${moduleName}/CHECK_PHONE_CODE_SUBMIT`,
};
