import { LOCATION_CHANGE } from 'connected-react-router';

import { RouterActionTypes, RouterStateType } from './types';

const initialState: RouterStateType = {
  breadcrumbs: [],
};

export default function (state = initialState, action: any): RouterStateType {
  switch (action.type) {
    case LOCATION_CHANGE:
      return {
        ...state,
        ...initialState,
      };
    case RouterActionTypes.SET_BREADCRUMBS:
      return {
        ...state,
        breadcrumbs: action.payload,
      };
    default:
      return state;
  }
}
