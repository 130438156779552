import baseStyled, { css as cssStyled, ThemedStyledInterface } from 'styled-components';

const theme = {
  colors: {
    primary: '#FC3B38',
    baseText: '#333333',
    borderColor: '#d9d9d9',
  },
};

export type Theme = typeof theme;

export const styled = baseStyled as ThemedStyledInterface<Theme>;
export const css = cssStyled;

export default theme;
