declare global {
  interface Window {
    ENV: {
      APP_ENV: string;
      API_URL: string;
      WEB_URL: string;
      SENTRY_DSN: string;
      TINY_KEY: string;
      RELEASE_VERSION: string;
    };
  }
}

export type Envs = 'localhost' | 'production';

export const APP_ENV = (window.ENV.APP_ENV || process.env.APP_ENV || 'localhost') as Envs;

export const API_URL = window.ENV.API_URL || process.env.REACT_APP_API_URL;
export const WEB_URL = window.ENV.WEB_URL || process.env.REACT_APP_WEB_URL;
export const SENTRY_DSN = window.ENV.SENTRY_DSN || process.env.REACT_APP_SENTRY_DSN;
export const TINY_KEY = window.ENV.TINY_KEY || process.env.REACT_APP_TINY_KEY;
export const RELEASE_VERSION = window.ENV.RELEASE_VERSION || process.env.RELEASE_VERSION;
