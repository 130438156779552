import React from 'react';
import {
  AimOutlined,
  BankOutlined,
  BarChartOutlined,
  BookOutlined,
  CoffeeOutlined,
  CommentOutlined,
  ContainerOutlined,
  CustomerServiceOutlined,
  FileSearchOutlined,
  FileTextOutlined,
  LockOutlined,
  MergeCellsOutlined,
  PieChartOutlined,
  QuestionCircleOutlined,
  SettingOutlined,
  SketchOutlined,
  StarOutlined,
  TeamOutlined,
  WalletOutlined,
} from '@ant-design/icons';

import { RouterConfigType } from './types';

const routerConfig: RouterConfigType = {
  Main: {
    path: '/',
    exact: true,
    location: 'Main',
    name: 'Главная страница',
  },
  Login: {
    path: '/login',
    exact: true,
    location: 'Login',
    name: 'Войти',
    permissions: ['guest'],
  },
  UserPassword: {
    path: '/user/password',
    exact: true,
    location: 'ChangePassword',
    name: 'Сменить пароль',
    permissions: ['user'],
  },
  Dashboard: {
    path: '/dashboard',
    exact: true,
    location: 'Dashboard',
    name: 'Дашборд',
    permissions: ['user'],
    showAside: true,
    Icon: <BarChartOutlined />,
  },

  Customers: {
    exact: true,
    name: 'Клиенты',
    permissions: ['CUSTOMER'],
    showAside: true,
    Icon: <TeamOutlined />,
    childrens: {
      CustomersList: {
        path: '/customers',
        exact: true,
        location: 'Customers',
        name: 'Список клиентов',
        permissions: ['CUSTOMER'],
        showAside: true,
      },
      Conversation: {
        path: '/customers/conversation',
        exact: true,
        location: 'Conversation',
        name: 'Коммуникация с клиентами',
        permissions: ['CONVERSATION'],
        showAside: true,
      },
      Customer: {
        path: '/customers/:uuid',
        exact: true,
        location: 'Customer',
        name: 'Клиенты',
        permissions: ['CUSTOMER'],
        Icon: <TeamOutlined />,
      },
    },
  },

  Specialists: {
    path: '/specialists',
    exact: true,
    location: 'Specialists',
    name: 'Специалисты',
    permissions: ['SPECIALIST'],
    showAside: true,
    Icon: <TeamOutlined />,
  },
  Specialist: {
    path: '/specialists/:uuid',
    exact: true,
    location: 'Specialist',
    name: 'Специалист',
    permissions: ['SPECIALIST'],
  },
  Articles: {
    path: '/articles',
    exact: true,
    location: 'Articles',
    name: 'Статьи',
    permissions: ['ARTICLE'],
    showAside: true,
    Icon: <FileTextOutlined />,
  },
  Article: {
    path: '/articles/:uuid',
    exact: true,
    location: 'Article',
    name: 'Статья',
    permissions: ['ARTICLE'],
  },
  Treatments: {
    exact: true,
    name: 'Мероприятия',
    permissions: ['TREATMENT'],
    showAside: true,
    Icon: <CoffeeOutlined />,
    childrens: {
      TreatmentsList: {
        path: '/treatments',
        exact: true,
        location: 'Treatments',
        name: 'Список мероприятий',
        permissions: ['TREATMENT'],
        showAside: true,
      },
      Booking: {
        path: '/treatments/booking',
        exact: true,
        location: 'Booking',
        name: 'Бронирование мероприятий',
        permissions: ['BOOKING'],
        showAside: true,
      },
      PromotionHistory: {
        path: '/treatments/promotion/history',
        exact: true,
        location: 'PromotionHistory',
        name: 'История публикации',
        permissions: ['PROMOTION'],
        showAside: true,
      },
    },
  },
  Treatment: {
    path: '/treatments/:uuid',
    exact: true,
    location: 'Treatment',
    name: 'Мероприятие',
    permissions: ['TREATMENT'],
  },
  Companies: {
    path: '/companies',
    exact: true,
    location: 'Companies',
    name: 'Организаторы',
    permissions: ['COMPANY'],
    showAside: true,
    Icon: <BankOutlined />,
  },
  Company: {
    path: '/companies/:uuid',
    exact: true,
    location: 'Company',
    name: 'Организатор',
    permissions: ['COMPANY'],
  },
  Comments: {
    path: '/comments',
    exact: true,
    location: 'Comments',
    name: 'Комментарии',
    permissions: ['COMMENT'],
    showAside: true,
    Icon: <CommentOutlined />,
  },
  Comment: {
    path: '/comments/:uuid',
    exact: true,
    location: 'Comment',
    name: 'Комментарий',
    permissions: ['COMMENT'],
  },
  Reviews: {
    path: '/reviews',
    exact: true,
    location: 'Reviews',
    name: 'Отзывы',
    permissions: ['REVIEW'],
    showAside: true,
    Icon: <StarOutlined />,
  },
  Review: {
    path: '/reviews/:uuid',
    exact: true,
    location: 'Review',
    name: 'Отзыв',
    permissions: ['REVIEW'],
  },
  SegmentsFolder: {
    exact: true,
    name: 'Сегментация',
    permissions: ['SEGMENT'],
    path: '/segments',
    location: 'Segments',
    showAside: true,
    Icon: <PieChartOutlined />,
  },

  Geo: {
    exact: true,
    name: 'Геоданные',
    permissions: ['GEO'],
    showAside: true,
    Icon: <AimOutlined />,
    childrens: {
      Cities: {
        path: '/geo/cities',
        exact: true,
        location: 'Cities',
        name: 'Города',
        permissions: ['GEO'],
        showAside: true,
      },
      Countries: {
        path: '/geo/countries',
        exact: true,
        location: 'Countries',
        name: 'Страны',
        permissions: ['GEO'],
        showAside: true,
      },
    },
  },

  Moderation: {
    exact: true,
    name: 'Модерация',
    permissions: ['MODERATION'],
    showAside: true,
    Icon: <FileSearchOutlined />,
    childrens: {
      ModerationSpecialists: {
        path: '/moderation/specialists',
        exact: true,
        location: 'ModerationSpecialists',
        name: 'Специалисты',
        permissions: ['MODERATION'],
        showAside: true,
      },
      ModerationSpecialist: {
        path: '/moderation/specialists/:uuid',
        exact: true,
        location: 'ModerationSpecialist',
        name: 'Специалист',
        permissions: ['MODERATION'],
      },
      ModerationArticles: {
        path: '/moderation/articles',
        exact: true,
        location: 'ModerationArticles',
        name: 'Статьи',
        permissions: ['MODERATION'],
        showAside: true,
      },
      ModerationArticle: {
        path: '/moderation/articles/:uuid',
        exact: true,
        location: 'ModerationArticle',
        name: 'Статья',
        permissions: ['MODERATION'],
      },
      ModerationTreatments: {
        path: '/moderation/treatments',
        exact: true,
        location: 'ModerationTreatments',
        name: 'Мероприятия',
        permissions: ['MODERATION'],
        showAside: true,
      },
      ModerationTreatment: {
        path: '/moderation/treatments/:uuid',
        exact: true,
        location: 'ModerationTreatment',
        name: 'Мероприятие',
        permissions: ['MODERATION'],
      },
      ModerationCompanies: {
        path: '/moderation/companies',
        exact: true,
        location: 'ModerationCompanies',
        name: 'Организаторы',
        permissions: ['MODERATION'],
        showAside: true,
      },
      ModerationCompany: {
        path: '/moderation/companies/:uuid',
        exact: true,
        location: 'ModerationCompany',
        name: 'Организатор',
        permissions: ['MODERATION'],
      },
      ModerationComments: {
        path: '/moderation/comments',
        exact: true,
        location: 'ModerationComments',
        name: 'Комментарии',
        permissions: ['MODERATION'],
        showAside: true,
      },
      ModerationComment: {
        path: '/moderation/comments/:uuid',
        exact: true,
        location: 'ModerationComment',
        name: 'Комментарий',
        permissions: ['MODERATION'],
      },
      ModerationReviews: {
        path: '/moderation/reviews',
        exact: true,
        location: 'ModerationReviews',
        name: 'Отзывы',
        permissions: ['MODERATION'],
        showAside: true,
      },
      ModerationReview: {
        path: '/moderation/reviews/:uuid',
        exact: true,
        location: 'ModerationReview',
        name: 'Отзыв',
        permissions: ['MODERATION'],
      },
      Moderators: {
        path: '/moderation/statistic-moderators',
        exact: true,
        location: 'Moderators',
        name: 'Модераторы',
        permissions: ['MODERATION_STATISTICS'],
        showAside: true,
      },
      ModerationStat: {
        path: '/moderation/statistic',
        exact: true,
        location: 'ModerationStat',
        name: 'Статистика модерации',
        permissions: ['MODERATION_STATISTICS'],
        showAside: true,
      },
    },
  },
  Tariffs: {
    exact: true,
    name: 'Финансы',
    permissions: ['PROMOTION'],
    showAside: true,
    Icon: <WalletOutlined />,
    childrens: {
      TariffsManagement: {
        path: '/tariffs/management',
        exact: true,
        location: 'TariffsManagement',
        name: 'Управление тарифами',
        permissions: ['PROMOTION'],
        showAside: true,
      },
      TariffsOrders: {
        path: '/tariffs/orders',
        exact: true,
        location: 'TariffsOrders',
        name: 'Счета/Заказы',
        permissions: ['PROMOTION'],
        showAside: true,
      },
      TariffsOffers: {
        path: '/tariffs/offers',
        exact: true,
        location: 'TariffsOffers',
        name: 'Предложения',
        permissions: ['PROMOTION'],
        showAside: true,
      },
      Payments: {
        path: '/payments',
        exact: true,
        location: 'Payments',
        name: 'Платежи',
        permissions: ['PAYMENT'],
        showAside: true,
      },
      Payment: {
        path: '/payments/:uuid',
        exact: true,
        location: 'Payment',
        name: 'Платеж',
        permissions: ['PAYMENT'],
      },
    },
  },
  Astro: {
    exact: true,
    name: 'Белый кролик',
    permissions: ['ASTRO'],
    showAside: true,
    Icon: <MergeCellsOutlined />,
    childrens: {
      AstroMotivationList: {
        path: '/astro/motivation',
        exact: true,
        location: 'AstroMotivationList',
        name: 'Мотивация',
        permissions: ['ASTRO'],
        showAside: true,
      },
      AstroMotivation: {
        path: '/astro/motivation/:id',
        exact: true,
        location: 'AstroMotivation',
        name: 'Мотивация',
        permissions: ['ASTRO'],
      },
      AstroPurposeList: {
        path: '/astro/purpose',
        exact: true,
        location: 'AstroPurposeList',
        name: 'Предназначение',
        permissions: ['ASTRO'],
        showAside: true,
      },
      AstroPurpose: {
        path: '/astro/purpose/:id',
        exact: true,
        location: 'AstroPurpose',
        name: 'Предназначение',
        permissions: ['ASTRO'],
      },
      AstroDesignList: {
        path: '/astro/human-design',
        exact: true,
        location: 'AstroDesignList',
        name: 'Профили/Жизненная роль',
        permissions: ['ASTRO'],
        showAside: true,
      },
      AstroDesign: {
        path: '/astro/human-design/:id',
        exact: true,
        location: 'AstroDesign',
        name: 'Профили/Жизненная роль',
        permissions: ['ASTRO'],
      },
      AstroSphereList: {
        path: '/astro/sphere',
        exact: true,
        location: 'AstroSphereList',
        name: 'Сферы реализации',
        permissions: ['ASTRO'],
        showAside: true,
      },
      AstroSphere: {
        path: '/astro/sphere/:id',
        exact: true,
        location: 'AstroSphere',
        name: 'Сфера реализации',
        permissions: ['ASTRO'],
      },
      AstroTalentList: {
        path: '/astro/talent',
        exact: true,
        location: 'AstroTalentList',
        name: 'Таланты',
        permissions: ['ASTRO'],
        showAside: true,
      },
      AstroTalent: {
        path: '/astro/talent/:id',
        exact: true,
        location: 'AstroTalent',
        name: 'Таланты',
        permissions: ['ASTRO'],
      },

      AstroTariffs: {
        path: '/astro/tariffs',
        exact: true,
        location: 'AstroTariffs',
        name: 'Тарифы',
        permissions: ['ASTRO_PACK'],
        showAside: true,
      },
    },
  },
  Dictionaries: {
    exact: true,
    name: 'Словари',
    permissions: ['DICTIONARY'],
    showAside: true,
    Icon: <BookOutlined />,
    childrens: {
      Audiences: {
        path: '/dictionaries/audiences',
        exact: true,
        location: 'DictionaryAudiences',
        name: 'Целевая аудитория',
        permissions: ['DICTIONARY'],
        showAside: true,
      },
      Lines: {
        path: '/dictionaries/lines',
        exact: true,
        location: 'DictionaryLines',
        name: 'Направления',
        permissions: ['DICTIONARY'],
        showAside: true,
      },
      Line: {
        path: '/dictionaries/lines/:id',
        exact: true,
        location: 'DictionaryLine',
        name: 'Направление',
        permissions: ['DICTIONARY'],
      },
      Problematics: {
        path: '/dictionaries/problematic',
        exact: true,
        location: 'DictionaryProblematics',
        name: 'Проблематики',
        permissions: ['DICTIONARY'],
        showAside: true,
      },
      Problematic: {
        path: '/dictionaries/problematic/:id',
        exact: true,
        location: 'DictionaryProblematic',
        name: 'Проблематика',
        permissions: ['DICTIONARY'],
      },
      DictionaryRoles: {
        path: '/dictionaries/roles',
        exact: true,
        location: 'DictionaryRoles',
        name: 'Роли',
        permissions: ['DICTIONARY'],
        showAside: true,
      },
      DictionaryTreatments: {
        path: '/dictionaries/treatments',
        exact: true,
        location: 'DictionaryTreatments',
        name: 'Типы мероприятий',
        permissions: ['DICTIONARY'],
        showAside: true,
      },
      Rubrics: {
        path: '/dictionaries/rubrics',
        exact: true,
        location: 'DictionaryRubrics',
        name: 'Рубрики мероприятий',
        permissions: ['DICTIONARY'],
        showAside: true,
      },
      StopWords: {
        path: '/dictionaries/stop-words',
        exact: true,
        location: 'DictionaryWords',
        name: 'Стоп-слова',
        permissions: ['DICTIONARY'],
        showAside: true,
      },
      Criterias: {
        path: '/dictionaries/criterias',
        exact: true,
        location: 'Criterias',
        name: 'Оценки',
        permissions: ['DICTIONARY'],
        showAside: true,
      },
    },
  },
  Advertisement: {
    exact: true,
    name: 'Реклама',
    permissions: ['BANNER'],
    showAside: true,
    Icon: <SketchOutlined />,
    childrens: {
      BannersList: {
        path: '/banners',
        exact: true,
        location: 'Banners',
        name: 'Баннеры',
        permissions: ['BANNER'],
        showAside: true,
      },
    },
  },
  Faq: {
    exact: true,
    name: 'FAQ',
    permissions: ['FAQ'],
    showAside: true,
    Icon: <QuestionCircleOutlined />,
    path: '/faq',
    location: 'Faq',
  },
  FaqItem: {
    path: '/faq/:id',
    exact: true,
    location: 'FaqItem',
    name: 'Вопрос',
    permissions: ['FAQ'],
  },
  Support: {
    exact: true,
    name: 'Поддержка',
    permissions: ['SUPPORT'],
    showAside: true,
    Icon: <CustomerServiceOutlined />,
    childrens: {
      SupportList: {
        path: '/support/list',
        exact: true,
        location: 'SupportList',
        name: 'Список обращений',
        permissions: ['SUPPORT'],
        showAside: true,
      },
      SupportSubjects: {
        path: '/support/subject',
        exact: true,
        location: 'SupportSubjects',
        name: 'Темы обращений',
        permissions: ['SUPPORT'],
        showAside: true,
      },
    },
  },
  Documents: {
    exact: true,
    path: '/documents',
    name: 'Документы',
    permissions: ['DOCUMENT'],
    showAside: true,
    location: 'Documents',
    Icon: <ContainerOutlined />,
  },
  Document: {
    exact: true,
    path: '/documents/:id',
    name: 'Документ',
    permissions: ['DOCUMENT'],
    location: 'Document',
  },
  WebConfig: {
    exact: true,
    name: 'Конфигурация',
    permissions: ['WEB_SEO'],
    showAside: true,
    Icon: <SettingOutlined />,
    childrens: {
      WebSeo: {
        path: '/web/seo',
        exact: true,
        location: 'WebSeo',
        name: 'SEO',
        permissions: ['WEB_SEO'],
        showAside: true,
      },
      WebRedirects: {
        path: '/web/redirects',
        exact: true,
        location: 'WebRedirects',
        name: 'Редиректы на сайте',
        permissions: ['WEB_SEO'],
        showAside: true,
      },
    },
  },
  Qualifiers: {
    path: '/qualifiers',
    exact: true,
    location: 'Qualifiers',
    name: 'Фичи',
    permissions: ['QUALIFIER'],
    showAside: true,
    Icon: <SettingOutlined />,
  },
  Access: {
    exact: true,
    name: 'Доступы',
    permissions: ['EMPLOYEE_MANAGER', 'EMPLOYEE_ACL'],
    showAside: true,
    Icon: <LockOutlined />,
    childrens: {
      Employees: {
        path: '/employees',
        exact: true,
        location: 'Employees',
        name: 'Сотрудники',
        permissions: ['EMPLOYEE_MANAGER'],
        showAside: true,
      },
      Roles: {
        path: '/roles',
        exact: true,
        location: 'Roles',
        name: 'Роли',
        permissions: ['EMPLOYEE_ACL'],
        showAside: true,
      },
      Emulation: {
        path: '/emulation',
        exact: true,
        location: 'Emulation',
        name: 'Эмуляция логина',
        permissions: ['PENETRATION_CUSTOMER'],
        showAside: true,
      },
    },
  },

  SettingsCommon: {
    path: '/settings',
    exact: true,
    location: 'Settings',
    name: 'Настройки',
    permissions: ['CONFIG'],
    showAside: true,
    Icon: <SettingOutlined />,
  },
  NoMatch: {
    path: '/404',
    location: '404',
  },
};

export default routerConfig;
