import { put, takeEvery } from 'redux-saga/effects';

import { ActionType } from 'modules/meta/types';

import { removeLoading, setLoading } from './actions';

function* setSaga(action: ActionType<unknown>) {
  if (action.meta?.key) {
    yield put(setLoading(action.meta.key));
  }
}

function* removeSaga(action: ActionType<unknown>) {
  if (action.meta?.key) {
    yield put(removeLoading(action.meta.key));
  }
}

export function* loadingSaga() {
  yield takeEvery((action: ActionType<unknown>) => /_REQUEST$/.test(action.type), setSaga);
  yield takeEvery(
    (action: ActionType<unknown>) => /_SUCCESS|_FAILURE$/.test(action.type),
    removeSaga
  );
}
