import { message as antdMessage } from 'antd';
import { takeEvery } from 'redux-saga/effects';

function* errorNotificationSaga(action: any) {
  if (
    action.type.indexOf('FAILURE') !== -1 &&
    !(action.payload && action.payload.status === 401) &&
    action.meta?.notification !== false &&
    !action.meta?.notification?.message
  ) {
    const message =
      (action.payload.data?.message || action.payload.data.error?.alert.message) ??
      'Что-то пошло не так';
    yield antdMessage.error(message);
  }
}

function* errorNotificationSagaWatcher() {
  yield takeEvery('*', errorNotificationSaga);
}

export default errorNotificationSagaWatcher;
