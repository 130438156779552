import { put, takeEvery } from 'redux-saga/effects';

import { ActionType } from 'modules/meta/types';

import { ErrorType } from './types';

import { removeErrors, setErrors } from './actions';
import { normalizeError } from './utils';

function* successSaga(action: ActionType<unknown>) {
  if (action.meta?.key) {
    yield put(removeErrors(action.meta.key));
  }
}

function* failureSaga(action: ActionType<{ data: { error: ErrorType } }>) {
  if (action.meta?.key) {
    yield put(
      setErrors({
        formKey: action.meta.key,
        // action.payload!.data.error might be deprecated
        error: normalizeError(action.payload!.data.error || action.payload!.data),
      })
    );
  }
}

export function* errorsSaga() {
  yield takeEvery((action: ActionType<unknown>) => /_SUCCESS$/.test(action.type), successSaga);
  yield takeEvery((action: ActionType<unknown>) => /_FAILURE$/.test(action.type), failureSaga);
}
