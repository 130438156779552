const moduleName = '@@errorMessages';

export const ErrorsActionTypes = {
  SET_FORM_ERROR_WATCHER: `${moduleName}/SET_FORM_ERROR_WATCHER`,
  CANCEL_FORM_ERROR_WATCHER: `${moduleName}/CANCEL_FORM_ERROR_WATCHER`,
  SET_ERRORS: `${moduleName}/SET_ERRORS`,
  REMOVE_ERRORS: `${moduleName}/REMOVE_ERRORS`,
};

export type ErrorsStateType = { [key: string]: ErrorType };

export type ErrorType = {
  code: string;
  message: string;
};
