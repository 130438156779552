import { isEnvironment } from 'config/utils';
import { all, fork } from 'redux-saga/effects';

import { RoutesType } from 'modules/router/types';
import authSaga from 'modules/auth/saga';
import callApiSaga from 'modules/callApiSaga';
import { errorsSaga } from 'modules/errors/saga';
import { loadingSaga } from 'modules/loading/saga';
import Meta from 'modules/meta';
import metaCallbackSaga from 'modules/metaCallbackSaga';
import NotificationModule from 'modules/notification';
import { relocateSaga, routerSaga } from 'modules/router/saga';
import { sentrySaga } from 'modules/sentry/saga';
import { successesSaga } from 'modules/successes/saga';
import { universalSaga } from 'modules/universal/saga';

const { metaSaga } = Meta;
const { metaNotificationsSaga, errorNotificationSaga } = NotificationModule;

const { callApiActionSaga } = callApiSaga;

const routes: RoutesType = {};

export default function* baseSaga(sagasArr: any) {
  if (!isEnvironment('localhost')) {
    yield fork(sentrySaga);
  }

  yield fork(errorNotificationSaga);
  yield fork(metaSaga, [callApiActionSaga, metaNotificationsSaga, metaCallbackSaga]);
  yield all(sagasArr);
  yield fork(authSaga);
  yield fork(errorsSaga);
  yield fork(successesSaga);
  yield fork(loadingSaga);
  yield fork(relocateSaga);
  yield fork(universalSaga);
  yield fork(routerSaga, routes);
}
