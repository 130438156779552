import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setNotification } from './actions';

export interface INotificationProps {
  render: Function;
  timeout?: number | null;
}

let timer: undefined | ReturnType<typeof setTimeout>;

export function Notification({ render, timeout }: INotificationProps) {
  const notification = useSelector((state: any) => state.notification);
  const dispatch = useDispatch();

  useEffect(() => {
    if (timer) {
      clearTimeout(timer);
    }

    if (timeout !== null) {
      timer = notification.isOpened
        ? setTimeout(() => {
            dispatch(setNotification());
          }, timeout || 2000)
        : undefined;
    }
  }, [notification.isOpened, dispatch, timeout]);

  return render({
    ...notification,
    onClose: () => {
      dispatch(setNotification());
    },
  });
}
