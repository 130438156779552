import { APP_ENV } from './env';

export type IsEnvironmentType = 'localhost' | 'testing' | 'staging' | 'production';

export function isEnvironment(environment: IsEnvironmentType): boolean {
  return environment === APP_ENV;
}

export const isClient = typeof window === 'object';
export const isProduction = isEnvironment('production');
