import { LOCATION_CHANGE, LocationChangeAction } from 'connected-react-router';
import { put, select, takeEvery } from 'redux-saga/effects';

import { AppState } from 'modules/store';

import { UniversalActionTypes } from './types';

import { clearUniversal } from './actions';
import { collectionUpdateMapper } from './utils';

let lastPathname = '';

function* locationChangeSaga(action: LocationChangeAction) {
  const pathname = action.payload.location.pathname;
  const isFirstRendering = action.payload.isFirstRendering;
  if (lastPathname !== pathname && !isFirstRendering) {
    yield put(clearUniversal());
  }

  lastPathname = pathname;
}

function* universalUpdateSaga(action: any) {
  if (!action.meta.key) {
    return;
  }

  if (!action.meta.universal?.isMutate) {
    return;
  }

  const [key, actionType] = action.meta.key.split('.');

  // UpdateSaga работает только для апдейта сущности;
  if (actionType !== 'update') {
    return;
  }

  const collection = yield select((state: AppState) => state.universal[key]);

  const mutation =
    action.meta.universal.type === 'collection'
      ? {
          ...collection,
          items: collection.items.map(collectionUpdateMapper(action)),
        }
      : { ...action.payload, _updatedAt: Date.now() };

  yield put({
    type: UniversalActionTypes.MUTATE_UNIVERSAL,
    payload: mutation,
    meta: { key },
  });
}

export function* universalSaga() {
  yield takeEvery(LOCATION_CHANGE, locationChangeSaga);
  yield takeEvery(UniversalActionTypes.UNIVERSAL_SUBMIT_SUCCESS, universalUpdateSaga);
}

export default { universalSaga };
